<template>
  <div class="banner-course">
    <div class="mb-4 d-flex justify-content-between">
      <h4 class="">Banner</h4>
      <div class="">
        <a-button v-if="!isEdit" @click.prevent="clickEdit" type="default">
          <edit-outlined></edit-outlined>
          Edit</a-button>
        <template v-else>
          <a-button @click.prevent="saveEdit" type="link">Simpan</a-button>
          <a-divider type="vertical" class="mx-1"></a-divider>
          <a-button @click.prevent="cancelEdit" type="link">Batal</a-button>
        </template>
      </div>
    </div>
    <div class="d-flex align-items-center">
      <div class="w-50 mr-3">
        <div class="text-center mb-3" v-if="bannerData.image">
          <a-button @click.prevent="deleteBanner()" type="danger">
            <delete-outlined></delete-outlined>
            Hapus Banner
          </a-button>
        </div>
        <a-upload
          class="th-upload"
          name="banner"
          list-type="picture-card"
          :show-upload-list="false"
          :action="`${backendUrl}api/banner/course/${idCourse}/upload`"
          :headers="{
            authorization: 'authorization-text',
          }"
          :custom-request="customRequestUpload"
        >
          <div v-if="bannerData.image" class="w-100 text-center">
            <img width="200" height="200" :src="`${backendUrl}${bannerData.image}`" alt="banner" />
          </div>
          <div v-else>
            <upload-outlined></upload-outlined>
            Unggah Banner
          </div>
        </a-upload>
      </div>
      <div>
        <div>
          <label for="name" class="font-weight-bold mb-0">Judul</label>
          <a-input v-if="isEdit" v-model:value="newBanner.name"></a-input>
          <p v-else>{{bannerData.name || '-'}}</p>
        </div>
        <div>
          <label for="Description" class="font-weight-bold mb-0">Deskripsi</label>
          <a-textarea v-if="isEdit" :rows="6" v-model:value="newBanner.description"></a-textarea>
          <p v-else>{{bannerData.description || '-'}}</p>
        </div>
        <div>
          <label for="isActive" class="font-weight-bold mb-0">Tampil di Dashboard ?</label>
          <div class="d-flex align-items-center" v-if="isEdit">
            <a-radio-group v-model:value="newBanner.isActive">
              <a-radio value="true">
                Ya
              </a-radio>
              <a-radio value="false">
                Tidak
              </a-radio>
            </a-radio-group>
          </div>
          <p v-else>{{bannerData.isActive ? 'Ya' : 'Tidak'}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createVNode } from 'vue'
import { PlusOutlined, UploadOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { reactive, ref, toRefs } from '@vue/reactivity';
import apiClient from '@/services/axios'
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { Modal, notification, message } from 'ant-design-vue';
export default {
  components: { UploadOutlined, EditOutlined, DeleteOutlined },
  props: {
    course: {
      type: Object,
      default: () => { return {} },
    },
    banner: {
      type: Object,
      default: () => { return {} },
    },
  },
  emits: ['fetchCourse', 'fetchBanner'],
  setup(props, { emit }) {
    const isEdit = ref(false)
    const route = useRoute()
    const idCourse = route.params.id
    const store = useStore()
    const newBanner = ref({
      name: null,
      description: null,
      isActive: 'false',
    })
    const { course:courseData } = toRefs(props)
    const { banner:bannerData } = toRefs(props)
    const saveEdit = async () => {
      try {
        Modal.confirm({
          title: `Apa anda yakin ingin mengubah data pada banner?`,
          icon: createVNode(ExclamationCircleOutlined),
          async onOk() {
            try {
              const storeAction = bannerData.value._id ? 'UPDATE_BANNER_COURSE' : 'CREATE_BANNER_COURSE'
              await store.dispatch('banner/' + storeAction, { idCourse, payload: {
                ...newBanner.value,
                isActive: JSON.parse(newBanner.value.isActive),
              } })
              isEdit.value = false
              newBanner.value = {
                name: null,
                description: null,
                isActive: 'false',
              }
              emit('fetchBanner')

              notification.success({
                message: `Berhasil Mengubah Data Pada Banner.`,
              })
            } catch (err) {
              console.log(err)
            }
          },
          onCancel() {},
        })
      } catch(err) {
        notification.error({
          message: `Gagal Mengubah Data Pada Banner.`,
        })
        console.log(err)
      }
    }
    const clickEdit = () => {
      isEdit.value = true
      const { value: banner } = bannerData
      if (banner._id) {
        newBanner.value = {
          name: banner.name,
          description: banner.description,
          isActive: banner.isActive ? JSON.stringify(banner.isActive) : 'false',
        }
      }
    }

    const cancelEdit = () => {
      isEdit.value = false
      emit('fetchCourse')
    }

    const beforeUpload = async file => {
      try {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

        if (!isJpgOrPng) {
          message.error('Anda hanya dapat mengunggah file JPG/PNG');
        }
        return isJpgOrPng;
      } catch(err) {
        console.log(err)
      }
    };

    const customRequestUpload = async (data) => {
      try {
        const isPassed = await beforeUpload(data.file)
        if (!isPassed)  {
          return ''
        } else {
          if (data.file.status !== 'uploading') {
          }
          const formData = new FormData()
          formData.append(data.filename, data.file)
          const { data: { data:res } } = await apiClient.post(data.action, formData, {
            ...data.headers,
          })

          message.success(`file ${data.file.name} berhasil diunggah.`);
        }
        emit('fetchBanner')
      } catch (err) {
        message.error(`file ${data.file.name} gagal diunggah.`);
        emit('fetchBanner')
        console.log(err)
      }
    }

    const deleteBanner = async() => {
      try {
        Modal.confirm({
          title: `Apa anda yakin ingin menghapus banner?`,
          icon: createVNode(ExclamationCircleOutlined),
          async onOk() {
            try {
              const data = await store.dispatch('banner/DELETE_BANNER', { id: bannerData.value._id })

              notification.success({
                message: `Berhasil Menghapus Banner.`,
              })
              emit('fetchBanner')
            } catch (err) {
              console.log(err)
            }
          },
          onCancel() {},
        })
      } catch(err) {
        console.log(err)
        notification.error({
          message: `Gagal Menghapus Banner.`,
        })
      }
    }

    return {
      courseData,
      newBanner,
      backendUrl: process.env.VUE_APP_BACKEND_URL,
      isEdit,
      clickEdit,
      cancelEdit,
      saveEdit,
      bannerData,
      customRequestUpload,
      idCourse,
      deleteBanner,
    }
  },

}
</script>

<style lang="scss">
.banner-course {
  .th-upload {
    .ant-upload {
      width: 100%!important;
      min-height: 150px;
    }
  }
}
</style>
